/* src/components/Footer.css */

.footer {
    background-color: #f8f9fa;
    padding: 20px;
    text-align: center;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .footer-links a {
    text-decoration: none;
    color: #333;
    margin-right: 10px;
  }
  
  .footer-info {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .footer-content {
      flex-direction: column;
    }
    .footer-links {
      margin-bottom: 10px;
    }
  }
  