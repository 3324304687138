/* src/components/PaidToSiteDetail.css */

.site-detail-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .site-detail-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .site-detail-logo {
    width: 100px;
    height: 100px;
    object-fit: contain;
    margin-right: 20px;
    border-radius: 20%;
  }
  
  .site-detail-title {
    font-size: 2rem;
  }
  
  .site-detail-subtitle {
    font-size: 1.2rem;
    color: #666;
  }
  
  .site-detail-description {
    margin-bottom: 20px;
  }
  
  .site-detail-info {
    border-top: 1px solid #ddd;
    padding-top: 20px;
  }
  
  .site-detail-info p {
    margin-bottom: 10px;
  }
  
  .site-detail-info strong {
    font-weight: bold;
  }
  
  .site-detail-buttons {
    margin-top: 20px;
    display: flex;
    gap: 10px;
  }
  
  .site-detail-buttons .button {
    padding: 12px 24px;
    font-size: 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 20px;
    background-color: #0056b3;
  }
  