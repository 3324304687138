/* src/components/PaidToSitesList.css */

.container {
    display: flex;
    flex-direction: row;
    max-width: 1550px;
    margin: 0 auto;
    padding: 20px;
}

.filter-bar {
    flex: 0 0 150px;
    margin-right: 20px;
}

.filter-buttons {
    display: flex;
    flex-direction: column;
}

.site-list {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(425px, 1fr)); /* Increase the min-width here */
    gap: 20px;
	max-width: 1375px; /* Set max-width to control the overall width */
}

@media (max-width: 768px) {
    .container {
        flex-direction: column;
        padding: 10px;
    }

    .filter-bar {
        width: 100%;
        margin-bottom: 10px;
    }

    .filter-buttons {
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        gap: 10px;
    }

    .sort-dropdown {
        margin-left: 10px;
        margin-bottom: 10px;
    }

    .site-list {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Increase the min-width for smaller screens */
        gap: 10px;
    }
}

.site-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    position: relative;
}

.site-header {
    display: flex;
    align-items: center;
}

.site-logo {
    width: 80px;
    height: 80px;
    object-fit: contain;
    margin-right: 20px;
    border-radius: 20%;
}

.site-title {
    font-size: 1.5rem;
    margin-bottom: auto;
}

.site-info {
    text-align: center;
    margin: 7.5px;
}

.button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 16px;
}

.toggle-button {
    margin-right: 5px;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 16px;
}

.button:hover {
    background-color: #0056b3;
}

.site-buttons {
    display: flex;
    gap: 10px;
}

.referal {
    background-color: #28a745;
}

.info-icon {
    position: absolute;
    right: 20px;
}

.filter-button {
    background-color: #000000;
    color: #ffffff;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 12px;
    padding: 10px 20px;
    margin: 5px;
}

.grey-button {
    background-color: #6c757d;
}

.filter-info {
    display: flex;
    justify-content: center;
    margin: 10px;
}

.sort-dropdown {
    background-color: #000000;
    color: #ffffff;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-size: 12px;
    padding: 10px 20px;
    margin: 5px;
}